import { ThemeProvider, GlobalStyles } from '@mui/material'
import theme from 'commons/constants/theme'
import Navigator from 'commons/navigation/Navigator'
import './firebase/FirebaseConfig'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Helmet } from 'react-helmet'
function App() {
	return (
		<ThemeProvider theme={theme}>
			<Helmet>
				<title>Puppy Foundations</title>
				<meta
					name="description"
					content="Descubre Puppy Foundations: la plataforma de adopción de mascotas que te conecta con tu próximo compañero peludo. Explora perfiles de mascotas, aprende sobre sus personalidades y encuentra tu pareja perfecta. Adopta un amigo, cambia una vida."
				/>
			</Helmet>
			<GlobalStyles
				styles={{
					'@font-face': [
						{
							fontFamily: 'Poppins',
							src: `url(${require('./assets/fonts/Poppins-Bold.ttf')}) format('truetype')`,
							fontWeight: 700,
							fontStyle: 'normal',
						},
						{
							fontFamily: 'Poppins',
							src: `url(${require('./assets/fonts/Poppins-Light.ttf')}) format('truetype')`,
							fontWeight: 300,
							fontStyle: 'normal',
						},
						{
							fontFamily: 'Poppins',
							src: `url(${require('./assets/fonts/Poppins-Regular.ttf')}) format('truetype')`,
							fontWeight: 400,
							fontStyle: 'normal',
						},
					],
				}}
			/>
			<ToastContainer
				position="bottom-left"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<Navigator />
		</ThemeProvider>
	)
}

export default App
