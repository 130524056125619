import { useEffect } from 'react'

const RedirectToStore = () => {
	useEffect(() => {
		const userAgent = navigator.userAgent || navigator.vendor
		let storeUrl

		if (/android/i.test(userAgent)) {
			storeUrl =
				'https://play.google.com/store/apps/details?id=com.survivorslabs.puppyfoundations'
		} else if (/iPad|iPhone|iPod/.test(userAgent)) {
			storeUrl = 'https://apps.apple.com/app/puppyfoundations'
		} else {
			storeUrl = 'https://puppyfoundations.org'
		}
		console.log('storeUrl', storeUrl)
		// Redirige al usuario
		window.location.href = storeUrl
	}, [])

	return null
}

export default RedirectToStore
