// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// PRODUCTION
const firebaseConfig = {
	apiKey: 'AIzaSyBRGCMDPx1FMEGhyYsX7KsEnyco-h5SCZg',
	authDomain: 'puppyfound-a629c.firebaseapp.com',
	databaseURL: 'https://puppyfound-a629c-default-rtdb.firebaseio.com',
	projectId: 'puppyfound-a629c',
	storageBucket: 'puppyfound-a629c.appspot.com',
	messagingSenderId: '413546775542',
	appId: '1:413546775542:web:1e6aa49ae79866bbf63dd7',
	measurementId: 'G-H4L444W3K4',
}

// const firebaseConfig = {
// 	apiKey: 'AIzaSyA7AlBywCdNjuT7PzI4IUXtuy9jK7LH6KE',
// 	authDomain: 'puppyfoundationsdev.firebaseapp.com',
// 	projectId: 'puppyfoundationsdev',
// 	storageBucket: 'puppyfoundationsdev.appspot.com',
// 	messagingSenderId: '652475342951',
// 	appId: '1:652475342951:web:5a1e327e612845b3f074c2',
// 	measurementId: 'G-VWXYGL0LGW',
// }

// Initialize Firebase
const app = initializeApp(firebaseConfig)
