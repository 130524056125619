import { CircularProgress, Grid } from '@mui/material'
import RedirectToStore from 'modules/download'
import { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

const Home = lazy(() => import('modules/home/Home'))
const Register = lazy(() => import('modules/auth/register/Register'))

export default function Navigator() {
	return (
		<BrowserRouter>
			<Suspense
				fallback={
					<Grid
						container
						sx={{
							width: '100vw',
							height: '100vh',
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<CircularProgress color="secondary" />
					</Grid>
				}
			>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/register" element={<Register />} />
					<Route path="/download" element={<RedirectToStore />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	)
}
