const colors = {
	primary: '#012138',
	secondary: '#2165BF',
	third: '#F26E22',
	dark: '#000',
	light: '#fff',
	disabled: '#939393',
	placeholderColor: '#939393',
	warning: '#FCBA12',
}

export { colors }
