import { createTheme } from '@mui/material'
import { colors } from './colors'

const theme = createTheme({
	palette: {
		primary: {
			main: colors.primary,
		},
		secondary: {
			main: colors.third,
		},
		error: {
			main: colors.warning,
		},
		text: {
			primary: colors.light,
		},
	},
	typography: {
		fontFamily: 'Poppins',
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightBold: 700,
	},

	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					backgroundColor: colors.light,
					color: colors.dark,
					borderRadius: 50,
					'& fieldset': {
						borderColor: colors.third,
					},
					'&:hover fieldset': {
						borderColor: colors.third,
					},
					'&.Mui-focused fieldset': {
						borderColor: colors.third,
					},
					// "&.Mui-error fieldset": {
					// 	borderColor: "red",
					// },
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: colors.light, // Reemplaza 'desiredColor' con el color que quieras usar.
					'&.Mui-focused': {
						color: colors.light, // Reemplaza 'desiredColor' con el color que quieras usar.
					},
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					color: '#000',
					'&.Mui-selected': {
						backgroundColor: colors.primary, // Reemplaza 'desiredColor' con el color que quieras usar.
					},
					'&:hover': {
						backgroundColor: colors.light, // Reemplaza 'desiredColor' con el color que quieras usar.
					},
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: colors.third, // Color del borde cuando no está seleccionado.
					'&.Mui-checked': {
						color: colors.third, // Color del borde cuando está seleccionado.
					},
				},
			},
		},
	},
})

export default theme
